import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import Tooltip from 'components/Tooltip/Tooltip'
import { BigNumber } from 'ethers'
import { formatAmount } from 'rfx/lib/numbers'
import { useCallback } from 'react'
import Typography from 'components/Common/Typography'

export function AprInfo({
  apr,
  incentiveApr,
  showTooltip = true,
  className,
}: {
  apr: BigNumber | undefined
  incentiveApr: BigNumber | undefined
  showTooltip?: boolean
  className?: string
}) {
  const totalApr = apr?.add(incentiveApr ?? 0) ?? BigNumber.from(0)
  const aprNode = (
    <Typography
      font="font-number"
      className={`w-fit border-b border-dashed border-th-fgd-3 pb-0.5 font-semibold text-th-fgd-1 ${className}`}
    >
      {apr ? `${formatAmount(totalApr, 2, 2)}%` : '...'}
    </Typography>
  )
  const renderTooltipContent = useCallback(() => {
    return (
      <>
        <StatsTooltipRow
          showDollar={false}
          label={`Base APR`}
          value={aprNode}
        />
        <StatsTooltipRow
          showDollar={false}
          label={`Bonus APR`}
          value={`${formatAmount(incentiveApr, 2, 2)}%`}
        />
        <br />
      </>
    )
  }, [apr, incentiveApr])
  return showTooltip && incentiveApr && incentiveApr.gt(0) ? (
    <Tooltip
      handle={aprNode}
      position="right-bottom"
      renderContent={renderTooltipContent}
    />
  ) : (
    aprNode
  )
}
