import { getContract } from 'config/contracts'
import { ethers } from 'ethers'
import useSWR from 'swr'
import CommonPool from 'abis/CommonPool.json'
import { getProvider } from 'rfx/lib/rpc'
import useWallet from 'rfx/lib/wallets/useWallet'
import { formatPrice } from 'apis/birdeye/helpers'
import { useTokenBalances } from 'domain/synthetics/tokens'
import { formatTokenAmount } from 'rfx/lib/numbers'
import { Token, getV2Tokens } from 'config/tokens'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Table } from '@components/shared/Table'

function CommonPoolList() {
  const { chainId } = useWallet()
  const provider = getProvider(undefined, chainId)
  const { balancesData } = useTokenBalances(chainId)
  const commonPoolAddress = getContract(chainId, 'commonPool')

  const marketToken = Object.values(getV2Tokens(chainId)).find(
    (item) => item.address === commonPoolAddress,
  ) as Token

  const contract = new ethers.Contract(
    getContract(chainId, 'commonPool'),
    CommonPool.abi,
    provider,
  )
  const { data: merklData } = useSWR(['merkl-data'])

  const { data } = useSWR(`common-pool-earn`, {
    fetcher: async () => {
      try {
        const totalSupply = await contract.totalSupply()
        const name = await contract.name()
        const convertToAssets = await contract.convertToAssets(
          Math.pow(10, 18).toString(), // decimals for share to asset price
        )
        return {
          totalSupply,
          convertToAssets,
          name,
        }
      } catch (e) {
        return {
          totalSupply: null,
          convertToAssets: null,
          name: null,
        }
      }
    },
  })

  const columns: ColumnDef<{
    id: string
    name: string
    totalSupply: any
    convertToAssets: any
  }>[] = useMemo(
    () => [
      {
        id: 'market',
        accessorKey: 'marketIndexName',
        header: 'Market',
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const data = row.original
          return (
            <div className="flex items-center gap-2">
              <div className="order-1 text-sm font-semibold text-th-fgd-1 lg:order-2">
                <div>{data?.name}</div>
              </div>
            </div>
          )
        },
      },
      {
        id: 'price',
        accessorKey: 'price',
        header: 'Price',
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const data = row.original
          return (
            <div className="text-sm font-medium text-th-fgd-1">
              {data?.convertToAssets
                ? formatPrice(data?.convertToAssets * Math.pow(10, -18), 4)
                : '...'}
            </div>
          )
        },
      },
      {
        id: 'totalSupply',
        accessorKey: 'totalSupply',
        header: 'Total Supply',
        meta: {
          align: 'left',
        },
        cell: ({ row }) => {
          const data = row.original

          return (
            <div className="flex flex-col text-sm font-medium text-th-fgd-1">
              {data?.totalSupply
                ? formatPrice(Number(data?.totalSupply * Math.pow(10, -18)))
                : '...'}
            </div>
          )
        },
      },
      {
        id: 'balance',
        accessorKey: 'balance',
        header: 'Wallet',
        meta: {
          align: 'left',
        },
        cell: () => {
          return (
            <div className="flex flex-col text-sm font-medium text-th-fgd-1">
              {balancesData &&
                formatTokenAmount(
                  balancesData?.[marketToken?.address] || 0,
                  marketToken?.decimals,
                  '',
                  {
                    useCommas: true,
                    displayDecimals: 4,
                  },
                )}
            </div>
          )
        },
      },
      {
        id: 'apr',
        accessorKey: 'apr',
        header: 'APR',
        meta: {
          align: 'left',
        },
        cell: () => {
          return (
            <div>
              {merklData
                ? merklData[getContract(chainId, 'commonPool')]?.apr.toFixed(
                    2,
                  ) || '-'
                : '-'}
              %
            </div>
          )
        },
      },
    ],
    [merklData, balancesData, data],
  )

  return (
    <div className="h-[calc(100%-40px)] overflow-y-scroll">
      <Table
        rows={
          data
            ? [
                {
                  id: 'vault1',
                  name: data?.name,
                  totalSupply: data?.totalSupply,
                  convertToAssets: data?.convertToAssets,
                },
              ]
            : []
        }
        columns={columns}
      />
    </div>
  )
}

export default CommonPoolList
