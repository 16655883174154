import Tooltip from '@components/shared/Tooltip'
import { formatUsd } from '@components/trade/rfx/tradingview/lib/numbers'
import useSettingsStore from '@store/settingsStore'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import {
  getMaxPoolAmountForDeposit,
  MarketInfo,
  MintableInfo,
} from 'domain/synthetics/markets'
import { TokenData } from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'
import { formatTokenAmount } from 'rfx/lib/numbers'
import React from 'react'
import Typography from 'components/Common/Typography'

interface Props {
  mintableInfo: MintableInfo | undefined
  market: MarketInfo | undefined
  token: TokenData
  longToken: TokenData
  shortToken: TokenData
}

const RenderMintableAmount = ({
  longToken,
  market,
  mintableInfo,
  shortToken,
  token,
}: Props) => {
  const isPro = useSettingsStore((state) => state.isPro())

  const renderMaxTokenRow = (
    tokenLabel: string,
    depositCapacityAmount?: BigNumber,
    poolAmount?: BigNumber,
    maxPoolAmount?: BigNumber,
    decimals?: number,
    symbol?: string,
  ) => {
    const formattedCapacity = formatTokenAmount(
      depositCapacityAmount,
      decimals,
      symbol,
      {
        useCommas: true,
      },
    )

    const formattedPoolAmount = formatTokenAmount(poolAmount, decimals, '', {
      useCommas: true,
      displayDecimals: 0,
    })

    const formattedMaxPoolAmount = formatTokenAmount(
      maxPoolAmount,
      decimals,
      symbol,
      {
        useCommas: true,
        displayDecimals: 0,
      },
    )

    return (
      <StatsTooltipRow
        label={`Max ${tokenLabel}`}
        value={[
          formattedCapacity,
          `(${formattedPoolAmount} / ${formattedMaxPoolAmount})`,
        ]}
      />
    )
  }

  return (
    <Tooltip
      className="text-none"
      placement="bottom"
      content={
        <>
          <p className="text-th-fgd-1">
            {longToken.symbol} and {shortToken.symbol} can be used to buy RP
            tokens for this market up to the specified buying caps.
          </p>
          <br />

          {renderMaxTokenRow(
            longToken.symbol,
            mintableInfo?.longDepositCapacityAmount,
            market?.longPoolAmount,
            getMaxPoolAmountForDeposit(market as MarketInfo, true),
            longToken.decimals,
            longToken.symbol,
          )}

          {renderMaxTokenRow(
            shortToken.symbol,
            mintableInfo?.shortDepositCapacityAmount,
            market?.shortPoolAmount,
            getMaxPoolAmountForDeposit(market as MarketInfo, false),
            shortToken.decimals,
            shortToken.symbol,
          )}
        </>
      }
    >
      <div className="flex flex-col text-sm font-medium text-th-fgd-1">
        <Typography font="font-number">
          {formatTokenAmount(
            mintableInfo?.mintableAmount,
            token.decimals,
            'RP',
            {
              useCommas: true,
              displayDecimals: 0,
            },
          )}
        </Typography>
        {isPro && (
          <Typography font="font-number" className="text-th-fgd-3">
            (
            {formatUsd(mintableInfo?.mintableUsd, {
              displayDecimals: 0,
            })}
            )
          </Typography>
        )}
      </div>
    </Tooltip>
  )
}

export default RenderMintableAmount
