import { BigNumber } from 'ethers'
import { formatAmount } from 'rfx/lib/numbers'
import React from 'react'
import { cn } from 'utils/classnames'
import Typography from 'components/Common/Typography'

const ToolTipRow = ({
  label,
  value,
  labelClassName,
}: {
  label: string
  value: string | number | JSX.Element
  labelClassName?: string
}) => {
  return (
    <div className="flex items-center justify-between">
      <span className={cn('text-xs font-medium text-th-fgd-3', labelClassName)}>
        {label}
      </span>
      <span className="text-xs font-medium text-th-fgd-1">{value}</span>
    </div>
  )
}

export const APRTooltip = ({
  baseAPR,
  // boost,
}: {
  baseAPR: BigNumber
  boost: string
  bonusAPR?: BigNumber
  igniteAPR: number
}) => {
  const aprNode = (
    <Typography font="font-number" className={`font-semibold text-th-fgd-1`}>
      {baseAPR ? `${formatAmount(baseAPR, 2, 2)}%` : '...'}
    </Typography>
  )

  return (
    <>
      <div className="flex flex-col gap-2 p-2">
        {baseAPR.gt(0) && (
          <ToolTipRow label="BASE APR (7 day average)" value={aprNode} />
        )}
        {/* {bonusAPR?.gt(0) && (
          <ToolTipRow
            label="BONUS APR (estimated)"
            value={`${(+ethers.utils.formatUnits(bonusAPR, 30)).toFixed(2)}%`}
          />
        )}
        {igniteAPR > 0 && (
          <ToolTipRow label="Ignite APR" value={`${igniteAPR.toFixed(2)}%`} />
        )} */}
        {/* <ToolTipRow
        labelClassName="text-xs font-medium text-th-fgd-1"
        label="Rewards"
        value={boost || '-'}
      /> */}
      </div>
    </>
  )
}
