import RenderMintableAmount from '@components/earn/RenderMintableAmount'
import Button from '@components/shared/Button'
import RenderSwapOnlyTokens from '@components/shared/RenderSwapOnlyTokens'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import { BigNumber, ethers } from 'ethers'
import { formatAmount, formatTokenAmount, formatUsd } from 'rfx/lib/numbers'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { cn } from 'utils/classnames'
import { useBonusAPR } from 'hooks/useBonusAPR'
import { getBoostandTotalTVLPool } from 'components/Synthetics/GmList/APRCell'
import { AprInfo } from 'components/AprInfo/AprInfo'

interface Props {
  data: PoolDataType
  totalTVLgrouped: {
    ZK: BigNumber
    'wETH-USDC.e': BigNumber
    stETH: BigNumber
  }
}

const GmListCard = ({ data, totalTVLgrouped }: Props) => {
  const router = useRouter()
  const { setMarketDetailAddress, setOnPoolDetailsPage } = useCreatePoolStore()

  const handlePoolClick = (address: string, tokenAddress: string) => {
    setMarketDetailAddress(address)
    setOnPoolDetailsPage(true)
    router.replace(
      {
        search: `?market=${tokenAddress}`,
      },
      undefined,
      {
        shallow: true,
      },
    )
  }

  const { totalTVLPool, group } = getBoostandTotalTVLPool(data, totalTVLgrouped)

  const bonusAPR = useBonusAPR(group, totalTVLPool)

  return (
    <div className="flex flex-col gap-4 rounded border border-th-input-border p-4">
      <div className="flex flex-col gap-4 border-b border-th-input-border pb-4">
        <div className="flex w-full justify-between">
          <div className="flex w-1/3 flex-col">
            <span className="text-xs font-normal text-th-fgd-1">MARKET</span>
            <div className="flex items-center gap-2">
              <div className="flex flex-col whitespace-nowrap">
                <span>{data.marketIndexName}</span>
                <span>[{data.marketPoolName}]</span>
              </div>
              {data.market?.isSpotOnly ? (
                <RenderSwapOnlyTokens
                  indexTokenSymbol={data.indexToken.symbol}
                  marketPoolName={data.marketPoolName}
                  size={16}
                />
              ) : (
                <RenderTokenIcon size="small" symbol={data.indexToken.symbol} />
              )}
            </div>
          </div>
          <InfoCard
            label="PRICE"
            value={formatUsd(data.price, {
              displayDecimals: 3,
            })}
            align="center"
          />
          <InfoCard
            label="APR"
            value={
              <div>
                <AprInfo apr={data.apr} incentiveApr={undefined} />
              </div>
            }
            align="right"
          />
        </div>
        <div className="flex w-full justify-between">
          <InfoCard
            label="TOTAL SUPPLY"
            value={formatTokenAmount(
              data.totalSupply,
              data.token.decimals,
              'RP',
              {
                useCommas: true,
                displayDecimals: 2,
              },
            )}
          />
          <InfoCard
            label="BUYABLE"
            value={
              <RenderMintableAmount
                mintableInfo={data.mintableInfo}
                market={data.market}
                token={data.token}
                longToken={data.longToken}
                shortToken={data.shortToken}
              />
            }
            align="center"
          />
          <InfoCard
            label="WALLET"
            value={formatTokenAmount(data.balance, data.token.decimals, 'RP', {
              useCommas: true,
              displayDecimals: 2,
              fallbackToZero: true,
            })}
            align="right"
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <SecondaryInfoCard
          heading={'Base APR'}
          subHeading={'7 day average'}
          value={formatAmount(data.apr, 2, 2) + '%'}
        />

        <SecondaryInfoCard
          heading={'Bonus APR'}
          subHeading={'estimated'}
          value={
            bonusAPR
              ? `${(+ethers.utils.formatUnits(bonusAPR, 30)).toFixed(2)}%`
              : ''
          }
        />
      </div>
      <Button
        size="large"
        secondary
        className="relative w-full select-none border-th-input-border bg-th-bkg-2 outline-none hover:!border-th-input-border"
        onClick={() =>
          handlePoolClick(
            data.market?.marketTokenAddress || '',
            data.token.address,
          )
        }
      >
        <span className="text-xs font-semibold text-th-fgd-1">
          VIEW DETAILS
        </span>
      </Button>
    </div>
  )
}

const InfoCard = ({
  value,
  label,
  align = 'left',
}: {
  label: string
  value?: string | ReactNode
  align?: 'center' | 'right' | 'left'
}) => {
  return (
    <div
      className={cn(
        'flex w-1/3 flex-col gap-2',
        align === 'right'
          ? 'items-end'
          : align === 'center'
            ? 'items-center'
            : 'items-start',
      )}
    >
      <span className="text-xs font-normal text-th-fgd-1">{label}</span>
      <span className="text-xs font-medium text-th-fgd-1">{value}</span>
    </div>
  )
}

const SecondaryInfoCard = ({
  heading,
  subHeading,
  value,
}: {
  heading: string
  subHeading: string
  value: string
}) => {
  return (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-2 text-xs font-medium text-th-fgd-3">
        <p className="w-16">{heading}</p>
        <p>({subHeading})</p>
      </div>
      <div className="text-xs font-medium text-th-fgd-1">{value}</div>
    </div>
  )
}

export default GmListCard
